/* This example requires Tailwind CSS v2.0+ */
import TweetIcon from '../assets/tweet_icon.png'
import Joe from '../assets/joe_wheat.png'
import Hunter from '../assets/hunter_miller.png'
import Trevor from '../assets/trevor_leavitt.png'
import Jason from '../assets/jason_stewart.png'

const people = [
    {
        id: 1,
        name: 'Joe Wheat',
        href: 'https://twitter.com/joewheat27',
        imageSrc: Joe,
        imageAlt: "Joseph Tanner Wheat",
        color: 'Black',
        description: "Creator and Co-Conductor of the BYU Hype Train"
      },
    {
    id: 2,
    name: 'Hunter Miller',
    href: 'https://twitter.com/MillerHHunter',
    imageSrc: Hunter,
    imageAlt: "Front of men's Basic Tee in black.",
    color: 'Black',
    description: "Co-Conductor of the BYU Hype Train"
    },
    {
    id: 3,
    name: 'Trevor Leavitt',
    href: 'https://twitter.com/leavitt_trevor',
    imageSrc: Trevor,
    imageAlt: "Front of men's Basic Tee in black.",
    color: 'Black',
    description: "Co-Conductor of the BYU Hype Train"
    },
    {
    id: 4,
    name: 'Jason Stewart',
    href: 'https://twitter.com/J_Stew50',
    imageSrc: Jason,
    imageAlt: "Front of men's Basic Tee in black.",
    color: 'Black',
    description: "Co-Conductor of the BYU Hype Train"
    },
    // More products...
  ]

export default function Squad() {
    return (
      <> 
        <div className="bg-white">
        <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-6xl font-extrabold tracking-tight text-gray-900">The Squad</h2>
  
          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {people.map((person) => (
              <div key={person.id} className="group relative">
                <div className="flex -space-x-2 overflow-hidden">
                  <img
                    src={person.imageSrc}
                    alt={person.imageAlt}
                    className="inline-block h-50 w-50 rounded-full ring-2 ring-white"
                  />
                </div>
                <div className="mt-4 flex justify-center">
                  <div>
                    <h3 className="text-xl text-center text-gray-700">
                        <span aria-hidden="true" className="" />
                        {person.name}

                    </h3>
                    <div className="text-center">
                        <p>
                            {person.description}
                        </p>
                        <a className="justify-center" href={person.href}>
                        <img
                            src={TweetIcon}
                            alt="Twitter"
                            className=" inline-block h-10 w-10 rounded-full ring-2 ring-white"
                        />
                    </a>
                    </div>
                    
                  </div>
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>
      </>
    )
  }

  