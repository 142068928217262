/* This example requires Tailwind CSS v2.0+ */
import AppleIcon from '../assets/apple_podcast_icon.png'
import SpotifyIcon from '../assets/spotify_podcast_icon.png'

const episodes = [
    {
      name: 'BYU downs Arizona State',
      number: '1',
      appleLink: 'https://podcasts.apple.com/us/podcast/episode-1-byu-downs-arizona-state/id1586819866?i=1000536153919',
      spotifyLink: 'https://open.spotify.com/episode/2C2nZERd7ireoOYY4vqTxh'
    },
    {
        name: 'USF Preview',
        number: '2',
        appleLink: 'https://podcasts.apple.com/us/podcast/episode-2-usf-preview/id1586819866?i=1000536456353',
        spotifyLink: 'https://open.spotify.com/episode/50ieEB0xnWgkbyBQ11EVnb'
    },
    {
        name: 'Episode 3 - USF Recap with Masen Wake',
        number: '3',
        appleLink: 'https://podcasts.apple.com/us/podcast/episode-3-usf-recap-with-masen-wake/id1586819866?i=1000536869838',
        spotifyLink: 'https://open.spotify.com/episode/3liQ8ZEPu08z7DxWL4gI6d'
    }
  ]

export default function Podcast() {
  return (
    <>
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">

            <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                        <th
                            scope="col"
                            className="px-10 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Episode Name
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Episode Number
                        </th>
                        <th
                            scope="col"
                            className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Apple Podcasts
                        </th>
                        <th
                            scope="col"
                            className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Spotify Podcasts
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                        </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {episodes.map((episode) => (
                        <tr key={episode.email}>
                            <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                                <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">{episode.name}</div>
                                </div>
                            </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{episode.number}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                            <a href={episode.appleLink} className="text-indigo-600 hover:text-indigo-900">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0 h-10 w-10">
                                    <img className="h-10 w-10 rounded-full" src={AppleIcon} alt="" />
                                    </div>
                                </div>
                            </a>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <a href={episode.spotifyLink} className="text-indigo-600 hover:text-indigo-900">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0 h-10 w-10">
                                    <img className="h-10 w-10 rounded-full" src={SpotifyIcon} alt="" />
                                    </div>
                                    </div>
                                </a>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>  
          </main>
      </div>
    </div>
    <div className="py-36 bg-white">
    </div>

    </>
  )
}
