// import logo from './logo.svg';
import './App.css';
import Nav from './components/Nav'
import Squad from './components/Squad'
import Home from './components/Home'
import Podcast from './components/Podcast'
import ScrollToTop from "react-router-scroll-top"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Footer from "./components/Footer"

function App() {
  return (
    <Router>
			<Nav />
			<ScrollToTop>
				<Switch>
					<Route exact path="/">
						<Home/>
					</Route>
					<Route path="/squad" exact>
						<Squad />
					</Route>
					<Route path="/podcast" exact>
						<Podcast />
					</Route>
				</Switch>
			</ScrollToTop>
			<Footer />
		</Router>
  );
}

export default App;
